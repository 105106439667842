import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { initialize as initializeAuthService } from '@seft/auth-service';
import { initialize as initializeFirestore } from '@seft/firestore-service';
import { reset } from '@seft/design-tokens/assets/css/reset';
import { initialize as initializeRoutes } from './pages/registry';

/**
 * Inject our imported CSS reset values, this allows us to share these values with our web components as they do not
 *  support the @import context for CSS within a component and esbuild defaults to global imports for CSS files which
 *  cannot pierce the shadow DOM.
 */
document.head.insertAdjacentHTML('beforeend', `<style>@layer reset { ${reset} }</style>`);

/**
 * @todo should this be stored directly in the code base?
 */
const firebaseConfig = {
  apiKey: 'AIzaSyAjJiHh2RSUSC0JheBxc-02Hoc3oA9mK04',
  authDomain: 'website-1f930.firebaseapp.com',
  databaseURL: 'https://website-1f930-default-rtdb.firebaseio.com',
  projectId: 'website-1f930',
  storageBucket: 'website-1f930.appspot.com',
  messagingSenderId: '1022924445785',
  appId: '1:1022924445785:web:2efefc0fda58f83d8eb948',
  measurementId: 'G-PLGTFY1HQW',
};
const app = initializeApp(firebaseConfig);
// const analytics =
getAnalytics(app);
void initializeAuthService();
void initializeRoutes();
initializeFirestore(app, 'murdock-brothers');
