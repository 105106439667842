import { html } from 'lit';
import RouterService from '@seft/router-service';
import type { Route } from '@seft/router-service/types';

export enum Pages {
  Home = 'Home',
  Contact = 'Contact Us',
  Inventory = 'Inventory',
  ProductDetails = 'Product Details',
}
/**
 * All possible routes in the application, navItem determines if it's directly accessible as a header link.
 */
export const registry: Route<Pages>[] = [
  {
    name: Pages.Home,
    pattern: /^\/$/,
    navItem: true,
    importer: () => import('./home'),
    renderer: () => html`<pages-home></pages-home>`,
  },
  {
    name: Pages.Contact,
    pattern: /^\/contact$/,
    navItem: true,
    importer: () => import('./contact'),
    renderer: () => html`<pages-contact></pages-contact>`,
  },
  {
    name: Pages.Inventory,
    pattern: /^\/inventory$/,
    navItem: true,
    importer: () => import('./inventory'),
    renderer: () => html`<pages-inventory></pages-inventory>`,
  },
  {
    name: Pages.ProductDetails,
    pattern: /^\/inventory\/(?<productId>[\w-]+)$/,
    navItem: false,
    importer: () => import('./product-details'),
    renderer: (params = {}) => html`<pages-product-details productId=${params['productId']}></pages-product-details>`,
  },
];
export function initialize() {
  registry.forEach((route) => RouterService.add(route));
}